import React from "react"
import { Box, Button, ButtonGroup, Heading, Show, Text, Link } from "@chakra-ui/react"

import { useRoutes } from "@app/hooks/useRoutes"
import { AnalyticProps, withSection } from "@app/hoc/Section"
import ResponsiveImage from "@app/components/ResponsiveImage"

type Props = GatsbyTypes.SanitySectionHero & AnalyticProps
type Alignment = "left" | "centre" | "right" | undefined

const Hero: React.FC<Props> = ({ title, description, links, alignment, image, innerRef, handleTrackingClick, tag }) => {
  const { urlResolver } = useRoutes()
  const lcAlignment = (alignment?.toLowerCase() as Alignment) || "left"
  const flexAligns = {
    left: "flex-start",
    centre: "center",
    right: "flex-end",
  }
  const flexAlign = flexAligns[lcAlignment]

  return (
    <Box as="section" pos="relative" ref={innerRef}>
      <Box pos="relative" pb={{ base: "clamp(17.5rem, 74.66%, 35rem)", lg: "min(34.72%, 31.25rem)" }}>
        <ResponsiveImage
          image={image}
          breakpoint="lg"
          imageProps={{ pos: "absolute", top: "0", left: "0", w: "full", h: "full", objectFit: "cover" }}
        />
      </Box>

      <Box
        pos="absolute"
        top="0"
        left="0"
        d={{ lg: "flex" }}
        flexDir={{ lg: "column" }}
        alignItems={{ lg: flexAlign }}
        justifyContent={{ lg: "center" }}
        w="full"
        h="full"
        zIndex="dropdown"
        py={{ base: "10", "2xl": "8.25rem" }}
        px={{ base: "4", lg: "min(8.4%, 7.5rem)" }}
      >
        <Box
          d={{ base: "flex" }}
          flexDir="column"
          alignItems={{ base: "center" }}
          justifyContent={{ base: "center" }}
          w="full"
          h="full"
          textAlign={{ base: "center" }}
          maxW={{ lg: "26.25rem" }}
        >
          {title && (
            <Heading as={tag} size="h1" color="background.white" maxW={{ base: "min(73.68%, 23.4375rem)", lg: "none" }} mb={{ base: "4" }}>
              {title}
            </Heading>
          )}

          {description && (
            <Show above="lg">
              <Text size="paragraphLarge" color="background.white" mb="4">
                {description}
              </Text>
            </Show>
          )}
          {links && !!links.length && (
            <ButtonGroup
              variant="outlineInverted"
              flexDir={{ base: "column", lg: "row" }}
              w="full"
              maxW={{ base: "container.xs", lg: "none" }}
              spacing="0"
              gap={{ base: "4" }}
              justifyContent="center"
            >
              {links.map(link => (
                <Button
                  key={link?._key}
                  as={Link}
                  href={urlResolver(link).url}
                  isExternal={link?.external}
                  onClick={handleTrackingClick}
                  variant={link?.buttonStyle?.variant || "outlineInverted"}
                  flex={link?.buttonStyle?.width === "full" ? "none" : "1"}
                >
                  {link?.title}
                </Button>
              ))}
            </ButtonGroup>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(withSection(Hero))
